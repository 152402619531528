import React from 'react'
import '../components/css/layout.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../components/css/policy.css'
import Digi from 'images/digiprotec.jpg'
import {Image} from 'react-bootstrap'

const Policy = () => (
  <div>
    <Header/>
    <div id="digiprotec-section">
      <Image src={Digi} alt="digiprotec" width="100%" />
    </div>
    <Footer></Footer>
  </div>
)
export default Policy
